<template>
  <div>
    <breadcrumbs
      :breadcrumb-items="breadcrumbItems"
    />
    <UserToolbar
      v-if="account && Object.keys(account).length !== 0"
      :account="account"
    />
    <b-card>
      <WidgetToolbar
        v-if="Object.keys(widget).length !== 0"
        :widget="widget"
        closing
      />
      <hr>
      <div class="table-filters d-flex justify-content-between align-items-center mb-25">
        <div>
          <h4>{{ $t('Statistics') }}</h4>
        </div>
        <div class="d-flex align-items-center">
          <div class="col-picker">
            <div class="d-flex align-items-center">
              <feather-icon
                icon="CalendarIcon"
                size="16"
              />
              <flat-pickr
                v-model="rangePicker"
                :config="{ mode: 'range'}"
                class="form-control flat-picker bg-transparent border-0 shadow-none"
                placeholder="YYYY-MM-DD"
                @on-close="toggleRange"
              />
            </div>
          </div>
          <div v-if="authUserData.role.includes('admin')">
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="primary"
              size="sm"
              class="btn-icon ml-25"
              :to="{ name: 'dashboard-site-widget-stats' }"
            >
              V1
            </b-button>
          </div>
        </div>
      </div>
      <div
        v-if="loading"
        class="d-flex justify-content-center mb-1"
      >
        <b-spinner label="Loading..." />
      </div>
      <div v-else>
        <b-table
          small
          :items="rows"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          responsive
          striped
          foot-clone
        >
          <template #head(datetime)>
            {{ $t('stats.date') }}
          </template>
          <template #head(impressions)>
            {{ $t('stats.shows') }}
          </template>
          <template #head(click)>
            {{ $t('stats.clicks') }}
          </template>
          <template #head(revenue)>
            {{ $t('stats.amount') }}
          </template>
          <template #cell(date)="row">
            <a
              href="#"
              :class="row.item._showDetails === true ? 'text-secondary' : ''"
              @click.prevent="showDetails(row)"
            >
              <strong>{{ getFormattedDate(new Date(row.item.date)) }}</strong>
              <feather-icon
                class="ml-15"
                :icon="row.item._showDetails === true ? 'ChevronDownIcon' : 'ChevronRightIcon'"
                size="20"
              />
            </a>
          </template>
          <template #row-details="{ item }">
            <div v-if="item">
              <div
                v-if="loading_hourly && !statsDay[item.date]"
                class="text-center"
              >
                <b-spinner label="Loading..." />
              </div>

              <b-table
                v-else-if="!loading_hourly && statsDay[item.date].length"
                small
                :items="statsDay[item.date]"
                :fields="fields"
                responsive
                striped
                thead-class="d-none"
                table-variant="primary"
              >
                <template #cell(date)="row">
                  {{ getFormattedDateTimeZone(row.item.date) }}
                </template>
                <template #cell(impressions)="data">
                  {{ data.value ? new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(data.value) : 0 }}
                </template>
                <template #cell(clicks)="data">
                  {{ data.value ? new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(data.value) : 0 }}
                </template>
                <template #cell(revenue)="data">
                  {{ data.value ? currency(account.currency, data.value) : 0 }}
                </template>
              </b-table>
            </div>
          </template>
          <template #cell(impressions)="data">
            {{ data.value ? new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(data.value) : 0 }}
          </template>
          <template #cell(clicks)="data">
            <a
              href="#"
              @click.prevent="toggleClick('clicks', data.item.date)"
            >

              {{ data.value ? new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(data.value) : 0 }}
              <feather-icon
                class="ml-15"
                :icon="toggleData['clicks'].includes(data.item.date) === true ? 'ChevronDownIcon' : 'ChevronRightIcon'"
                size="20"
              />
            </a>
            <div v-if="toggleData['clicks'].includes(data.item.date)">
              <div
                v-for="(value, name, idx) in showStatsRevenue('clicks', data.item.date)"
                :key="`clicks${idx}`"
              >
                <small
                  class="text-primary cursor-pointer"
                  @click="name !== 'banner' && name !== 'cpcfeed'
                    ? showSiteRevenueDetails(name, data.item.date, 'clicks')
                    : name !== 'cpcfeed'
                      ? showCampaignRevenueDetails(name, data.item.date, 'clicks')
                      : showCPCFeedDetails(name, data.item.date, 'clicks')"
                >
                  {{ $t(`stats.adstypes.${name}`) }}: {{ new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(value) }}
                </small>
              </div>
            </div>
          </template>
          <template #cell(revenue)="data">
            <a
              href="#"
              @click.prevent="toggleClick('revenue', data.item.date)"
            >
              {{ data.value ? currency(account.currency, data.value) : 0 }}
              <feather-icon
                class="ml-15"
                :icon="toggleData['revenue'].includes(data.item.date) === true ? 'ChevronDownIcon' : 'ChevronRightIcon'"
                size="20"
              />
            </a>
            <div v-if="toggleData['revenue'].includes(data.item.date)">
              <div
                v-for="(value, name) in showStatsRevenue('revenue', data.item.date)"
                :key="value"
              >
                <small
                  class="text-primary cursor-pointer"
                  @click="name !== 'banner' && name !== 'cpcfeed'
                    ? showSiteRevenueDetails(name, data.item.date, 'revenue')
                    : name !== 'cpcfeed'
                      ? showCampaignRevenueDetails(name, data.item.date, 'revenue')
                      : showCPCFeedDetails(name, data.item.date, 'revenue')"
                >
                  {{ $t(`stats.adstypes.${name}`) }}: {{ currency(account.currency, value) }}
                </small>
              </div>
            </div>
          </template>
          <template #foot(date)="data">
            <strong :data-label="data.label">{{ $t('Records') }}: {{ rows.length }}</strong>
          </template>
          <template #foot(clicks)="data">
            <span
              style="font-weight: bold; cursor: pointer;"
              @click.prevent="toggleTotals('clicks')"
            >
              <strong :data-label="data.label">{{ new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(totalFormat('clicks')) }}</strong>
              <feather-icon
                class="ml-15"
                :icon="toggleTotalsData['clicks'] ? 'ChevronDownIcon' : 'ChevronRightIcon'"
                size="20"
              />
            </span>
            <div v-if="toggleTotalsData['clicks']">
              <div
                v-for="(value, name) in statsRevenueMonthly.clicks"
                :key="value"
              >
                <small
                  class="text-primary cursor-pointer"
                  @click="name !== 'banner' && name !== 'cpcfeed'
                    ? showSiteRevenueTotalDetails(name, selectedRangeFormated.from, 'clicks')
                    : name !== 'cpcfeed'
                      ? showCampaignRevenueTotalDetails(name, selectedRangeFormated.from, 'clicks')
                      : showCPCFeedTotalDetails(name, selectedRangeFormated.from, 'clicks')"
                >
                  <strong style="text-transform: initial;">{{ $t(`stats.adstypes.${name}`) }}: {{ new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(value) }}</strong>
                </small>
              </div>
            </div>
          </template>
          <template #foot(impressions)="data">
            <strong :data-label="data.label">{{ new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(totalFormat('impressions')) }}</strong>
          </template>
          <template #foot(revenue)="data">
            <span
              style="font-weight: bold; cursor: pointer;"
              @click.prevent="toggleTotals('revenue')"
            >
              <strong :data-label="data.label">{{ currency(account.currency, totalFormat('revenue')) }}</strong>
              <feather-icon
                class="ml-15"
                :icon="toggleTotalsData['revenue'] ? 'ChevronDownIcon' : 'ChevronRightIcon'"
                size="20"
              />
            </span>
            <div v-if="toggleTotalsData['revenue']">
              <div
                v-for="(value, name) in statsRevenueMonthly.revenue"
                :key="value"
              >
                <small
                  class="text-primary cursor-pointer"
                  @click="name !== 'banner' && name !== 'cpcfeed'
                    ? showSiteRevenueTotalDetails(name, selectedRangeFormated.from, 'revenue')
                    : name !== 'cpcfeed'
                      ? showCampaignRevenueTotalDetails(name, selectedRangeFormated.from, 'revenue')
                      : showCPCFeedTotalDetails(name, selectedRangeFormated.from, 'revenue')"
                >
                  <strong style="text-transform: initial;">{{ $t(`stats.adstypes.${name}`) }}: {{ currency(account.currency, value) }}</strong></small>
              </div>
            </div>
          </template>
        </b-table>
        <b-modal
          id="modal-stats"
          ref="modal-stats"
          centered
          size="lg"
          :title="modalTitle"
          ok-only
          ok-title="Ok"
          scrollable
        >
          <SiteStatsModal
            :account-currency="account.currency"
            :sidebar-data="sidebarData"
            :sidebar-data-field="sidebarDataField"
            :sidebar-data-type="sidebarDataType"
          />
        </b-modal>
        <b-modal
          id="modal-stats-totals"
          ref="modal-stats-totals"
          centered
          size="lg"
          :title="modalTitleTotals"
          ok-only
          ok-title="Ok"
          scrollable
        >
          <SiteStatsModalTotals
            :account-currency="account.currency"
            :sidebar-data="sidebarDataTotals"
            :sidebar-data-field="sidebarDataField"
            :sidebar-data-type="sidebarDataType"
            :selected-range="selectedRangeFormated"
          />
        </b-modal>
      </div>
    </b-card>
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import store from '@/store/index'
import { getUserData } from '@/auth/utils'
import Ripple from 'vue-ripple-directive'
import {
  BCard,
  BButton,
  BTable,
  BSpinner,
  VBTooltip,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Breadcrumbs from '@/views/ui/breadcrumbs/Breadcrumbs.vue'
import UserToolbar from '@/views/dashboard/users/UserToolbar.vue'
import WidgetToolbar from '@/views/dashboard/widgets/WidgetToolbar.vue'
import flatPickr from 'vue-flatpickr-component'
import currencyFormatter from '@/utils/currency-formatter'
import getFormattedDate, { getFormattedDateTime, getFormattedDateTimeZone } from '@/utils/date-formatter'
import SiteStatsModal from '../sites/stats/SiteStatsModal.vue'
import SiteStatsModalTotals from '../sites/stats/SiteStatsModalTotals.vue'

export default {
  components: {
    Breadcrumbs,
    BButton,
    BCard,
    BTable,
    flatPickr,
    BSpinner,
    UserToolbar,
    WidgetToolbar,
    SiteStatsModal,
    SiteStatsModalTotals,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      authUserData: getUserData(),
      loading: true,
      loading_hourly: true,
      account: {},
      pageTitle: '',
      pageSubtitle: '',
      breadcrumbItems: [],
      typeStats: 'custom',
      site: [],
      rows: [],
      statsDay: {},
      statsRevenue: [],
      statsRevenueMonthly: {},
      toggleData: {
        clicks: [],
        revenue: [],
      },
      toggleTotalsData: {
        clicks: false,
        revenue: false,
      },
      sidebarData: [],
      sidebarDataTotals: {},
      revenueData: [],
      IsOpenSidebar: false,
      sidebarDataField: '',
      sidebarDataType: '',
      widget: {},
      fields: [
        { key: 'date', label: 'Date', tdClass: 'td-width' },
        { key: 'impressions', label: 'Impressions', tdClass: 'td-width' },
        { key: 'clicks', label: 'Clicks', tdClass: 'td-width' },
        { key: 'revenue', label: 'revenue', tdClass: 'td-width' },
      ],
      sortBy: 'datetime',
      sortDesc: true,
      sortDirection: 'asc',
      currency: currencyFormatter,
      getFormattedDate,
      getFormattedDateTime,
      getFormattedDateTimeZone,
      rangePicker: [getFormattedDate(new Date(new Date().getFullYear(), new Date().getMonth(), 1)), getFormattedDate(new Date())],
    }
  },
  computed: {
    totalAmount() {
      let totalAmount = 0
      this.rows.forEach(i => {
        totalAmount += i.revenue
      })
      return totalAmount
    },
    selectedRangeFormated() {
      let dateFrom
      let dateTo
      const selectedRange = this.rangePicker
      if (Array.isArray(selectedRange)) {
        [dateFrom, dateTo] = selectedRange
      } else {
        [dateFrom, , dateTo] = selectedRange.split(' ')
      }

      return {
        from: getFormattedDate(new Date(dateFrom)),
        to: dateTo === undefined ? getFormattedDate(new Date(dateFrom)) : getFormattedDate(new Date(dateTo)),
      }
    },
    modalTitle() {
      let title = ''

      if (this.sidebarData[0] !== undefined && this.sidebarDataType) {
        title = `${this.$i18n.t(`stats.adstypes.${this.sidebarDataType}`)} (${getFormattedDate(new Date(this.sidebarData[0].date))})`
      }

      return title
    },
    modalTitleTotals() {
      let title = ''

      if (Object.keys(this.sidebarDataTotals).length > 0 && this.sidebarDataType) {
        title = `${this.$i18n.t(`stats.adstypes.${this.sidebarDataType}`)} (${this.$i18n.t('date_range', { from: getFormattedDate(new Date(this.selectedRangeFormated.from)), to: getFormattedDate(new Date(this.selectedRangeFormated.to)) })})`
      }

      return title
    },
  },
  created() {
    this.getUserData()
    this.getSiteData()
    this.getWidgetData()
    // this.getWidgetStatsByType(true)
    this.getStatsV2Data()
    this.getStatsRevenue(this.selectedRangeFormated.from)
    this.getStatsRevenueMonthly(this.selectedRangeFormated.from)
  },
  methods: {
    totalFormat(field) {
      let total = 0
      this.rows.forEach(i => {
        total += i[field]
      })
      return total
    },
    async getUserData() {
      const responseData = await useJwt.getUser(this.$route.params.userId)
      this.account = responseData.data.account || {}
      this.pageTitle = this.account.name
      this.pageSubtitle = this.account.email
      this.setBreabcrumbs()
    },
    async getSiteData() {
      const responseData = await useJwt.getSite(this.$route.params.siteId)
      this.site = responseData.data.site || []
      this.setBreabcrumbs()
    },
    async setBreabcrumbs() {
      this.breadcrumbItems = [
        {
          text: this.$t('page.menu.users'),
          to: '/users',
        },
        {
          text: this.pageTitle,
          to: `/users/${this.$route.params.userId}`,
        },
        {
          text: 'Sites',
          to: `/users/${this.$route.params.userId}/sites`,
        },
        {
          text: this.site.title,
          to: `/users/${this.site.user_id}/sites/${this.site._id}`,
        },
        {
          text: this.$t('page.features.widgets'),
          to: `/users/${this.site.user_id}/sites/${this.site._id}/widgets/`,
        },
        {
          text: this.widget.title,
          active: true,
        },
      ]
    },
    async getWidgetData() {
      const responseData = await useJwt.getWidget(this.$route.params.widgetId)
      this.widget = responseData.data.widget
      this.setBreabcrumbs()
    },
    toggleRange(e) {
      this.rangePicker = e
      this.getStatsV2Data()
    },
    async getWidgetStatsByType(start = false) {
      this.loading = true
      let { from: dateFrom, to: dateTo } = this.selectedRangeFormated
      this.rows = null

      if (start) {
        const urlSearchParams = new URLSearchParams(window.location.search)
        const urlParams = Object.fromEntries(urlSearchParams.entries())

        if (urlParams.type) {
          this.typeStats = urlParams.type
        }

        if (urlParams.from) {
          dateFrom = urlParams.from
        }

        if (urlParams.to) {
          dateTo = urlParams.to
        }

        this.rangePicker = [dateFrom, dateTo]
      }

      this.$router.replace({
        name: 'dashboard-site-widget-stats-v2',
        query: { type: this.typeStats, from: dateFrom, to: dateTo },
      }).catch(() => {})

      const params = {
        from: dateFrom,
        site_id: this.$route.params.siteId,
        widget_id: this.$route.params.widgetId,
        to: dateTo,
        type: this.typeStats,
      }
      const responseData = await useJwt.getWidgetStatsByType(params)
      this.rows = responseData.data.widgetstats || []
      this.loading = false
    },
    async getStatsV2Data() {
      this.loading = true
      this.rows = []
      const params = {
        site_id: this.$route.params.siteId,
        widget_id: this.$route.params.widgetId,
        type: 'daily',
        with_sources: false,
      }
      const reqParams = Object.assign(params, this.selectedRangeFormated)
      const responseData = await useJwt.getSiteStatsV2ByType(reqParams)
      this.rows = responseData.data.items || []
      this.loading = false
    },
    changeType() {
      this.$router.replace({
        name: 'dashboard-site-widget-stats',
        query: { type: this.typeStats },
      })
      this.getStatsV2Data()
    },
    showDetails(row) {
      row.toggleDetails()
      if (row.item._showDetails) {
        if (!this.statsDay[row.item.date]) {
          this.getStatsDay(row.item.date)
        }
      }
    },
    async getStatsDay(day) {
      this.loading_hourly = true
      this.statsDay[day] = null
      const params = {
        site_id: this.$route.params.siteId,
        widget_id: this.$route.params.widgetId,
        type: 'hourly',
        date: getFormattedDate(new Date(day)),
        with_sources: false,
      }

      const responseData = await useJwt.getSiteStatsV2ByType(params)
      this.statsDay[day] = responseData.data.items || null
      this.loading_hourly = false
    },
    async getStatsRevenue(from) {
      this.statsRevenue = []
      const params = {
        site_id: this.$route.params.siteId,
        widget_id: this.$route.params.widgetId,
        type: 'daily',
        from,
      }
      const reqParams = Object.assign(params, this.selectedRangeFormated)
      const responseData = await useJwt.getSiteStatsRevenue(reqParams)
      this.statsRevenue = responseData.data.items || []
    },
    async getStatsRevenueMonthly(from) {
      this.statsRevenueMonthly = {}
      const params = {
        site_id: this.$route.params.siteId,
        widget_id: this.$route.params.widgetId,
        type: 'daily',
        from,
      }

      const responseData = await useJwt.getSiteStatsRevenueTotal(params)
      this.statsRevenueMonthly = responseData.data || {}
    },
    async showSiteRevenueDetails(type, date, field) {
      this.sidebarData = []
      this.sidebarDataField = field
      this.sidebarDataType = type
      const params = {
        site_id: this.$route.params.siteId,
        widget_id: this.$route.params.widgetId,
        type: 'daily',
        from: getFormattedDate(new Date(date)),
        ad_type: type,
      }

      await useJwt.getSiteStatsEcpm(params)
        .then(async response => {
          if (response.data && response.data.items && response.data.items.length > 0) {
            const currentItem = response.data.items.filter(i => i.date === date)
            this.sidebarData = currentItem
            const ids = Object.keys(currentItem[0][field]).filter(key => key !== 'others')
            if (ids.length > 0) {
              await store.dispatch('dashboard-partners-stats/fetchPartnerNamesById', ids)
            }

            // this.$root.$emit('bv::toggle::collapse', 'sidebar-right')
            this.$refs['modal-stats'].show()
          }
        })
        .catch(error => {
          if (error.response && error.response.status === 422) {
            if (error.response.data.errors.length) {
              error.response.data.errors.forEach(err => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Error',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    text: err.detail,
                  },
                })
              })
            }
          }
        })
    },
    async showCampaignRevenueDetails(type, date, field) {
      this.sidebarData = []
      this.sidebarDataType = type
      this.sidebarDataField = field

      const params = {
        site_id: this.$route.params.siteId,
        widget_id: this.$route.params.widgetId,
        type: 'daily',
        from: getFormattedDate(new Date(date)),
      }

      await useJwt.getStatsBannersSites(params)
        .then(async response => {
          if (response.data && response.data.items && response.data.items.length > 0) {
            const currentItem = response.data.items.filter(i => i.date === date)
            if (currentItem.length > 0) {
              this.sidebarData = currentItem
              const ids = Object.keys(currentItem[0][field]).filter(key => key !== 'others')
              if (ids.length > 0) {
                await store.dispatch('dashboard-campaings-stats/fetchCampaignNamesById', ids)
              }
              // this.$root.$emit('bv::toggle::collapse', 'sidebar-right')
              this.$refs['modal-stats'].show()
            }
          }
        })
        .catch(error => {
          if (error.response && error.response.status === 422) {
            if (error.response.data.errors.length) {
              error.response.data.errors.forEach(err => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Error',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    text: err.detail,
                  },
                })
              })
            }
          }
        })
    },
    async showCampaignRevenueTotalDetails(type, date, field) {
      this.sidebarDataTotals = {}
      this.sidebarDataType = type
      this.sidebarDataField = field

      const params = {
        site_id: this.$route.params.siteId,
        type: 'daily',
        from: getFormattedDate(new Date(date)),
      }

      await useJwt.getStatsBannersSites(params)
        .then(async response => {
          if (response.data && response.data.items && response.data.items.length > 0) {
            const newItems = this.getRangeTotalsByType(response.data.items, field)
            this.sidebarDataTotals = newItems
            const ids = Object.keys(newItems).filter(key => key !== 'others')
            if (ids.length > 0) {
              await store.dispatch('dashboard-campaings-stats/fetchCampaignNamesById', ids)
            }
            // this.$root.$emit('bv::toggle::collapse', 'sidebar-totals-right')
            this.$refs['modal-stats-totals'].show()
          }
        })
        .catch(error => {
          if (error.response && error.response.status === 422) {
            if (error.response.data.errors.length) {
              error.response.data.errors.forEach(err => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Error',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    text: err.detail,
                  },
                })
              })
            }
          }
        })
    },
    async showCPCFeedDetails(type, date, field) {
      this.sidebarData = []
      this.sidebarDataType = type
      this.sidebarDataField = field

      const params = {
        site_id: this.$route.params.siteId,
        widget_id: this.$route.params.widgetId,
        type: 'daily',
        from: getFormattedDate(new Date(date)),
        ad_type: 'cpcfeed',
      }

      await useJwt.getCampaignStatsEcpm(params)
        .then(async response => {
          if (response.data && response.data.items && response.data.items.length > 0) {
            const currentItem = response.data.items.filter(i => i.date === date)
            if (currentItem.length > 0) {
              this.sidebarData = currentItem
              const ids = Object.keys(currentItem[0][field]).filter(key => key !== 'others')
              if (ids.length > 0) {
                await store.dispatch('dashboard-sites-stats/fetchSiteNamesById', ids)
              }
              // this.$root.$emit('bv::toggle::collapse', 'sidebar-right')
              this.$refs['modal-stats'].show()
            }
          }
        })
        .catch(error => {
          if (error.response && error.response.status === 422) {
            if (error.response.data.errors.length) {
              error.response.data.errors.forEach(err => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Error',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    text: err.detail,
                  },
                })
              })
            }
          }
        })
    },
    async showCPCFeedTotalDetails(type, date, field) {
      this.sidebarData = []
      this.sidebarDataType = type
      this.sidebarDataField = field

      const params = {
        site_id: this.$route.params.siteId,
        widget_id: this.$route.params.widgetId,
        type: 'daily',
        from: getFormattedDate(new Date(date)),
        ad_type: 'cpcfeed',
      }

      await useJwt.getCampaignStatsEcpm(params)
        .then(async response => {
          if (response.data && response.data.items && response.data.items.length > 0) {
            const newItems = this.getRangeTotalsByType(response.data.items, field)
            this.sidebarDataTotals = newItems
            const ids = Object.keys(newItems).filter(key => key !== 'others')
            if (ids.length > 0) {
              await store.dispatch('dashboard-sites-stats/fetchSiteNamesById', ids)
            }
            this.$refs['modal-stats-totals'].show()
          }
        })
        .catch(error => {
          if (error.response && error.response.status === 422) {
            if (error.response.data.errors.length) {
              error.response.data.errors.forEach(err => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Error',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    text: err.detail,
                  },
                })
              })
            }
          }
        })
    },
    async showSiteRevenueTotalDetails(type, date, field) {
      this.sidebarDataTotals = {}
      this.sidebarDataType = type
      this.sidebarDataField = field

      const params = {
        site_id: this.$route.params.siteId,
        type: 'daily',
        from: getFormattedDate(new Date(date)),
        ad_type: type,
      }

      await useJwt.getSiteStatsEcpm(params)
        .then(async response => {
          if (response.data && response.data.items && response.data.items.length > 0) {
            const newItems = this.getRangeTotalsByType(response.data.items, field)
            this.sidebarDataTotals = newItems
            const ids = Object.keys(newItems).filter(key => key !== 'others')
            if (ids.length > 0) {
              await store.dispatch('dashboard-partners-stats/fetchPartnerNamesById', ids)
            }
            // this.$root.$emit('bv::toggle::collapse', 'sidebar-totals-right')
            this.$refs['modal-stats-totals'].show()
          }
        })
        .catch(error => {
          if (error.response && error.response.status === 422) {
            if (error.response.data.errors.length) {
              error.response.data.errors.forEach(err => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Error',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    text: err.detail,
                  },
                })
              })
            }
          }
        })
    },
    toggleClick(field, value) {
      const idx = this.toggleData[field].indexOf(value)

      if (idx !== -1) {
        this.toggleData[field].splice(idx, 1)
      } else {
        this.toggleData[field].push(value)
      }
    },
    toggleTotals(field) {
      this.toggleTotalsData[field] = !this.toggleTotalsData[field]
    },
    showStatsRevenue(field, date) {
      const currentDateData = this.statsRevenue.find(sr => sr.date === date)
      if (Object.keys(currentDateData).length > 0) {
        return currentDateData[field]
      }
      return ''
    },
    getRangeTotalsByType(data, field) {
      return data.reduce((acc, obj) => {
        Object.entries(obj[field]).forEach(([key, value]) => {
          acc[key] = (acc[key] || 0) + value
        })
        return acc
      }, {})
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style>
  .table-filters {
    display: flex;
    justify-content: space-between;
  }

  tfoot th {
    background-color: #fff!important;
  }

  .col-picker {
    min-width: 220px;
  }

  [dir] .table-responsive .table-responsive {
    margin-bottom: 0!important;
  }

  [dir] .table.b-table > tbody > tr.b-table-details > td {
    padding-left: 0!important;
    padding-right: 0!important;
    padding-bottom: 0!important;
    border-bottom: 1px solid #776bf0
  }

  [dir] .table.b-table > tbody > tr.b-table-details .table-striped tbody tr:nth-of-type(odd) {
    background: #ebebeb;
  }

  [dir] .table.b-table > tbody td.td-width {
    width: 11%;
  }

  @media (max-width: 1400px) {
    .table-filters {
      flex-wrap: wrap;
      margin-bottom: 15px;
    }

    .col-type {
      width: 60%;
    }
  }

  @media (max-width: 992px) {
    .col-type {
      width: 70%;
    }
  }

  @media (max-width: 800px) {
    .col-type {
      width: 100%;
    }
  }
</style>
